import { capitalize } from "@vue/shared"
import { format } from "date-fns"
import { ListParams } from "./api/base"
import Generate from "./api/crud"

type Status = "PENDING" | "SUCCESS" | "FAILURE"
type StatusText = "Pending" | "Success" | "Failure"

/**
 *  Values for `type` of `el-tag`
 */
type ElTagType = "default" | "success" | "danger"

interface Params extends ListParams {
	name: string
}

export interface TaskResult {
	date_done: string
	task_name: string
	task_args: string
	status: Status | string
}

interface TaskResultData extends TaskResult {
	status: Status
}

interface TaskResultView extends TaskResult {
	statusTag: ElTagType
	status: string
}

function statusTag(status: Status): ElTagType {
	if (status === "SUCCESS") return "success"
	if (status === "FAILURE") return "danger"
	return "default"
}

export function parse(t: TaskResultData): TaskResultView {
	return {
		...t,
		date_done: format(new Date(t.date_done), "PP pp"),
		status: capitalize(t.status.toLocaleLowerCase()),
		statusTag: statusTag(t.status),
	}
}

export default Generate<Params, TaskResultData, TaskResultData>("/taskresults")
