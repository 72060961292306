





























import { ref as _ref } from "@vue/composition-api";
import { defaultListQuery } from "@/mixins";
import Api, { parse, TaskResult } from "@/select2/taskresult";
import Pagination from "@/components/Pagination/index.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  let count = _ref(0);

  let listQuery = _ref({ ...defaultListQuery,
    name: ""
  });

  let loading = _ref(true);

  let taskResults = _ref(([] as TaskResult[]));

  async function load() {
    loading.value = true;
    const data = await Api.list(listQuery.value);
    taskResults.value = data.results.map(parse);
    count.value = data.count;
    loading.value = false;
  }

  async function search() {
    listQuery.value.page = 1;
    load();
  }

  load(); // @Component({
  // name: 'TaskResults',
  // components: { Pagination }
  // })
  // export default class extends mixins(PageMixin, ListMixin) {
  // getList = getTaskResults
  //
  // async beforeRouteEnter(to, from, next) {
  // const listQuery = { ...defaultListQuery }
  // const { data } = await getTaskResults(listQuery)
  // next((vm) => vm.setData(data))
  // }
  //
  // private async search() {
  // this.$router.push({ query: { q: this.listQuery.task_name } })
  // this.listQuery.page = 1
  // this.load()
  // }
  //
  // get displayList() {
  // return this.list.map((row) => ({
  // ...row,
  // date_done: format(new Date(row.date_done), 'PP pp'),
  // status: statusDisplay(row.status),
  // statusTag: statusTag(row.status)
  // }))
  // }
  //
  // get Status() {
  // return Status
  // }
  // }

  return {
    count,
    listQuery,
    loading,
    taskResults,
    load,
    search
  };
};

__sfc_main.components = Object.assign({
  Pagination
}, __sfc_main.components);
export default __sfc_main;
